import React from "react";
import PropTypes from "prop-types";
import { graphql, Link as GatsbyLink } from "gatsby";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { mapEdgesToNodes } from "../lib/helpers";
import GraphQLErrorList from "../components/GraphQLErrorList";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Section from "../components/Section";
import { errorType, featuredMediaType, sieveType } from "../types";
import FeaturedMedia from "../components/FeaturedMedia";
import MediaSearch from "../components/MediaSearch";
import AlbumMedia from "../components/AlbumMedia";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    featuredMedia: allSanityFeaturedMedia(
      limit: 1
      sort: { order: DESC, fields: _updatedAt }
    ) {
      edges {
        node {
          id
          image {
            alt
            caption
            asset {
              url
            }
          }
          media {
            title
            slug {
              current
            }
          }
        }
      }
    }
    latestAlbum: allSanityAlbum(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      limit: 1
    ) {
      ...AlbumEdge
    }
    media: allSanityMedia(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      ...MediaEdge
    }
    themes: allSanityTheme {
      ...ThemeEdge
    }
    categories: allSanityCategory {
      ...CategoryEdge
    }
  }
`;

function IndexPage({ data, errors, location }) {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const { site, featuredMedia, media, themes, categories, latestAlbum } =
    data || {};
  const featuredMediaNode = mapEdgesToNodes(featuredMedia)[0];
  const mediaNodes = mapEdgesToNodes(media);
  const themeNodes = mapEdgesToNodes(themes);
  const categoryNodes = mapEdgesToNodes(categories);
  const latestAlbumNode = mapEdgesToNodes(latestAlbum)[0];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const mediaSieve = {
    filter:
      location && location.state && location.state.filter
        ? { ...location.state.filter }
        : null,
    sort:
      location && location.state && location.state.sort
        ? { ...location.state.sort }
        : null,
  };

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />

      {featuredMediaNode && <FeaturedMedia featured={featuredMediaNode} />}

      <Section heading="Search Media" id="mediaSearch">
        <MediaSearch
          media={mediaNodes}
          themes={themeNodes}
          categories={categoryNodes}
          perPage={9}
          initialSieve={mediaSieve}
        />
      </Section>

      <Section heading="Latest Album" background="dark">
        <Typography variant="h3">
          <Link
            to={`/album/${latestAlbumNode.slug.current}`}
            component={GatsbyLink}
          >
            {latestAlbumNode.title}
          </Link>
        </Typography>
        <AlbumMedia album={latestAlbumNode} />
      </Section>
    </Layout>
  );
}

IndexPage.defaultProps = {
  errors: null,
  location: {
    state: {
      filter: null,
      sort: null,
    },
  },
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    media: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: featuredMediaType,
        })
      ),
    }),
  }).isRequired,
  errors: PropTypes.arrayOf(errorType),
  location: PropTypes.shape({
    state: sieveType,
  }),
};

export default IndexPage;
