import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Link as GatsbyLink } from "gatsby";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import { featuredMediaType } from "../../types";
import imageUrlFor from "../../lib/image-url";
import { getHeaderText } from "../../lib/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "450px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  container: {
    textAlign: "center",
  },
  text: {
    marginBottom: theme.spacing(4),
  },
  caption: {
    textAlign: "center",
  },
  source: {
    marginLeft: "auto",
    display: "inline-block",
  },
}));

function FeaturedMedia({ featured: { image, media } }) {
  if (!media) {
    return null;
  }

  const imageUrl = image.asset ? imageUrlFor(image).url() : null;
  const [title, source] = getHeaderText(media.title);
  const classes = useStyles();
  const theme = useTheme();
  const bgGradient = `linear-gradient(${fade(
    theme.palette.primary.dark,
    0.6
  )}, ${fade(theme.palette.primary.dark, 0.6)})`;
  const backgroundStyles = imageUrl
    ? `${bgGradient}, url(${imageUrl})`
    : bgGradient;

  return (
    <section
      style={{ backgroundImage: backgroundStyles }}
      className={classes.root}
    >
      <Container className={classes.container}>
        <div className={classes.text}>
          <Typography className={classes.caption} variant="h3" component="h1">
            {image.caption ? `"${image.caption}"` : `${title}`}
          </Typography>
          <Typography
            className={classes.source}
            variant="overline"
            component="span"
          >
            {source}
          </Typography>
        </div>
        <Button
          component={GatsbyLink}
          to={`media/${media.slug.current}`}
          variant="outlined"
          size="large"
        >
          View Featured
        </Button>
      </Container>
    </section>
  );
}

FeaturedMedia.propTypes = {
  featured: featuredMediaType.isRequired,
};

export default FeaturedMedia;
